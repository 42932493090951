export const useShopwarePickupLocationBySlug = (slug: string) => {
	const { apiClient } = useShopwareContext();
	return useAsyncData(`shopware:stores:${slug}`, async () => {
		const { data } = await apiClient.invoke("readPickupLocations post /pickup-location", {
			body: {
				limit: 1,
				associations: {
					businessHours: {},
					specialBusinessHours: {}
				},
				filter: [
					{
						field: "slug",
						type: "equals",
						value: slug
					}
				]
			}
		});

		return data?.elements?.[0] || null;
	});
};
