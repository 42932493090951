<script setup lang="ts">
import { generateOpeningHours } from "@/helpers/generators";
import type { StoryComponent } from "~/types/storyblok-generic";
import type { Option } from "@kippie/ui";
import type { StoreStoryblok } from "~/types/storyblok";
import { storeOpen } from "@/helpers/store-open";
import { SHIPPING_METHODS } from "~/lib/constants";
import { getPickupLocationAddress } from "@/helpers/pickupLocation";
import PickupLocationContactPrompt from "@/components/shared/PickupLocationContactPrompt.vue";
import type { Schemas } from "#shopware";

const route = useRoute();
const drawerProvider = useDrawer();
const { user } = useUser();

const [storyblokResponse, shopwareResponse] = await Promise.all([
	useStoryblokRouter<StoreStoryblok>(`/winkels/${route.params.name}`),
	useShopwarePickupLocationBySlug(`/winkels/${route.params.name}`)
]);

const { story, error } = storyblokResponse;
const { data: store, error: swError } = shopwareResponse;
const isSettingPickupLocation = ref(false);

if (error.value || swError.value || !store.value || !story.value) {
	throw createError({ statusCode: 404, message: "Page not found", cause: error, fatal: true });
}
const storyContent = computed(() => story.value?.content);

if (storyContent.value) {
	useStoryblokSeo(storyContent.value);
}

const accessToken = (useRuntimeConfig().public.mapbox as any).accessToken;

const { updateExtension, selectedPickupLocation } = useCart();
const { selectedShippingMethod, setShippingMethod } = useSessionContext();
const { onPickupLocationSelected } = useGtagEvents();
const isMobile = useMediaQuery("(max-width: 768px)");

const openingHours = computed<Option[]>(() => generateOpeningHours(store?.value?.businessHours || []));
const open = computed<boolean>(() => storeOpen(openingHours.value));

const setPickLocation = async () => {
	isSettingPickupLocation.value = true;
	if (selectedShippingMethod.value?.technicalName !== "pickup") {
		await setShippingMethod(SHIPPING_METHODS.pickup);
	}

	await updateExtension({
		pickupLocationId: store.value?.id,
		deliveryTimeSlot: null
	});

	try {
		onPickupLocationSelected(store.value as Schemas["KwbPickupLocation"]);
	} catch (error) {
		console.error("Error onPickupLocationSelected", error);
	}

	isSettingPickupLocation.value = false;
};
const today = ref<number>(new Date().getDay() - 1);

const contactPickupLocation = async () => {
	await drawerProvider.showDrawer(PickupLocationContactPrompt, {
		drawerTitle: `Contact opnemen met ${store.value?.name || "Kippie"}`,
		pickupLocation: store.value as Schemas["KwbPickupLocation"],
		defaultEmail: user.value?.email
	});
};
</script>

<template>
	<div class="bg-sand lg:bg-white">
		<div v-if="store && story?.content" class="grid lg:grid-cols-2 gap-x-10 max-w-none lg:container">
			<div class="container my-6 space-y-4 lg:hidden">
				<StoryblokBreadcrumbs class="w-fit" :current="store.name" />
				<h3 v-if="store.name" class="text-black text-4xl leading-[3.125rem] font-brush">
					{{ store.name }}
				</h3>
				<div
					v-if="story.content.description"
					v-html="renderRichText(story.content.description)"
					class="richtext"
				/>
			</div>
			<div
				class="relative z-10 order-2 py-10 -mt-6 bg-white rounded-t-lg lg:rounded-none lg:mt-0 lg:py-6 lg:order-1 lg:z-0"
			>
				<div class="container-padding lg:container-none">
					<div class="hidden lg:block">
						<StoryblokBreadcrumbs class="mb-6 w-fit" :current="store.name" />
						<h1 v-if="store.name" class="mb-10 text-4xl text-black font-brush">
							{{ store.name }}
						</h1>
					</div>
					<div class="grid gap-10 2xl:grid-cols-2 lg:gap-8">
						<div class="flex flex-col gap-8">
							<KippieOpeningHours
								variant
								title="Openingstijden"
								:opening-hours="openingHours"
								class="p-0 rounded-none"
							>
								<div class="flex items-center pb-4 gap-x-4">
									<span
										class="w-2 h-2 rounded-full animate-pulse"
										:class="[open ? 'bg-green' : 'bg-red']"
									/>
									<span class="text-base leading-7">
										{{ open ? `${story.content.open}:` : undefined }}
										{{ openingHours.slice(today)[0].value }}
									</span>
								</div>
							</KippieOpeningHours>
							<div
								v-if="renderRichText(story.content.openHoursInformation)"
								v-html="renderRichText(story.content.openHoursInformation)"
								class="p-4 text-sm rounded-lg richtext bg-sand"
							/>
						</div>
						<div class="space-y-4">
							<h4 class="text-3xl font-brush">
								{{ story.content.locationInformation || "" }}
							</h4>
							<p
								v-if="store.street && store.streetNumber && store.zipCode && store.city"
								class="text-base leading-7"
							>
								<span> {{ getPickupLocationAddress(store)?.address }}, </span>
								<span>
									{{ getPickupLocationAddress(store)?.city }}
								</span>
							</p>
							<div
								v-if="story.content.name && story.content.job && story.content.image"
								class="flex justify-between gap-3 p-5 text-base leading-7 border border-dashed rounded-lg border-brown"
							>
								<div class="flex flex-col">
									<span class="font-bold">{{ story.content.name }}</span>
									<span>{{ story.content.job }}</span>
									<span>{{ store.name || "" }}</span>
								</div>
								<NuxtImg
									:src="story.content.image.filename"
									:alt="story.content.image.alt"
									class="object-cover w-20 rounded-lg aspect-square"
									provider="storyblok"
									:modifiers="{ filters: { focal: story.content.image.focus } }"
									sizes="xl:100px lg:100px md:100px sm:100px xs:100px"
									:quality="90"
								/>
							</div>
							<div class="flex flex-col p-5 text-base leading-7 rounded-lg bg-sand gap-y-3">
								<a
									v-if="store.phoneNumber"
									:href="`tel:${store.phoneNumber}`"
									class="flex items-center gap-3"
								>
									<SvgIcon name="phone" class="w-6 h-6" />
									<span>
										{{ store.phoneNumber }}
									</span>
								</a>
								<!-- old email display on store page -->
								<!-- <a v-if="store.email" :href="`mailTo:${store.email}`" class="flex items-center gap-3">
									<SvgIcon name="envelope" class="w-6 h-6" />
									<span>
										{{ store.email }}
									</span>
								</a> -->
							</div>
							<KippieButton
								v-if="store.email"
								@click="contactPickupLocation"
								block
								size="small"
								color="yellow"
							>
								<template #left>
									<SvgIcon name="envelope" />
								</template>
								Contact opnemen
							</KippieButton>
							<KippieButton
								:disabled="selectedPickupLocation && selectedPickupLocation.id === store.id"
								@click="setPickLocation"
								block
								size="small"
								color="white"
								:loading="isSettingPickupLocation"
								v-if="store.isPickupLocation"
							>
								<template #left v-if="selectedPickupLocation && selectedPickupLocation.id === store.id">
									<SvgIcon name="check" />
								</template>
								Afhalen bij {{ store.name || "Kippie" }}
							</KippieButton>
						</div>
					</div>
				</div>
			</div>
			<Map
				class="w-full aspect-[80/73] order-1"
				:center="{ lat: store.latitude || 52.1326, lng: store.longitude || 5.2913 }"
				:zoom="+story.content.zoom || 17"
				:access-token="accessToken"
				:lazy="true"
			>
				<Marker
					:location="{ lat: store.latitude || 52.1326, lng: store.longitude || 5.2913 }"
					:icon="story.content.icon.filename"
				/>
			</Map>
		</div>
		<template v-if="story?.content">
			<template v-if="isMobile">
				<div
					v-for="(item, index) in story.content.body.find(
						(bodyItem: StoryComponent) => bodyItem.component === 'promotion-banner-section'
					)?.promotion_banners || []"
					:key="item._uid"
					:class="[index === 2 ? 'bg-sand' : 'bg-white']"
				>
					<div :class="[index === 1 ? 'py-10' : 'py-8']">
						<component
							:is="index === 0 ? 'h3' : 'h4'"
							class="container"
							:class="index === 0 ? 'text-4xl font-brush pb-4' : 'text-lg font-bold pb-2'"
						>
							{{ item.heading }}
						</component>
						<div v-html="renderRichText(item.text)" class="container richtext" />
					</div>
					<NuxtImg
						v-if="index === 0"
						class="object-cover w-full aspect-square"
						:src="item.image.filename"
						provider="storyblok"
						:modifiers="{ smart: true, filters: { focal: item.image.focus } }"
						:alt="item.image.alt"
						sizes="md:100vw sm:100vw xs:100vw"
					/>
				</div>
				<StoryblokComponent
					v-for="body in story.content.body.filter(
						(bodyItem: StoryComponent) => bodyItem.component !== 'promotion-banner-section'
					)"
					:key="body._uid"
					:blok="body"
					class="last:lg:border-t border-brown"
				/>
			</template>
			<template v-else>
				<StoryblokComponent
					v-for="body in story.content.body"
					:key="body._uid"
					:blok="body"
					class="last:lg:border-t border-brown"
				/>
			</template>
		</template>
	</div>
</template>
